<template>
  <div>
    <div>
      <el-button
        round
        class="btn-view-changes"
        @click="lidarComHistoricoAlteracoes()"
      >
        <i class="onpoint-clock-counter-clockwise icon-font pr-2"></i>
        Histórico de alterações
      </el-button>
    </div>

    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>

          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in historicoAlteracoes"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ formatarData(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Local:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ formatarLocal(alteracao) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao ? formatarAcao(alteracao.Acao) : "-" }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Bureau:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Antes.BureauId
                        ? formatarAlteracao(alteracao.Antes.BureauId)
                        : "-"
                    }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Bureau:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Depois.BureauId
                        ? formatarAlteracao(alteracao.Depois.BureauId)
                        : "-"
                    }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axiosInstance from "@/axios";

export default {
  name: "historico-alteracoes-bureaus-consulta",
  props: {
    listaConsultas: {
      type: Array,
      default: () => []
    },
    listaBureaus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      popupHistoricoAlteracoes: false,
      historicoAlteracoes: []
    };
  },
  methods: {
    async lidarComHistoricoAlteracoes() {
      this.historicoAlteracoes = [];

      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(`api/Parametros/ObterLogAuditoriaParametrosConsultasPorBureau`)
          .then(response => response.data.Response)
          .then(response => {
            let mudancas = response;
            for (let i = 0; i < mudancas.length; i++) {
              this.converterStringsParaObjetos(mudancas[i]);
            }

            this.historicoAlteracoes = this.inverterOrdemDoArray(mudancas);
          })
          .then(() => this.exibirHistoricoAlteracoes());
      });
    },

    exibirHistoricoAlteracoes() {
      this.popupHistoricoAlteracoes = true;
    },

    formatarData(date) {
      if (date instanceof Date) {
        date = date.setHours(0, 0, 0, 0);
      }
      return moment(date).format("DD/MM/YYYY");
    },

    formatarLocal(alteracao) {
      let tipoConsultaId = null;
      let local = "";

      if (alteracao.Antes.TipoConsultaId) {
        tipoConsultaId = alteracao.Antes.TipoConsultaId;
      } else if (alteracao.Depois.TipoConsultaId) {
        tipoConsultaId = alteracao.Depois.TipoConsultaId;
      } else {
        tipoConsultaId = 0;
      }

      if (tipoConsultaId === 0) return "Local da consulta não encontrado";

      this.listaConsultas.forEach(consulta => {
        if (consulta.TipoConsultaId === Number(tipoConsultaId)) {
          local = consulta.NomeConsulta;
        }
      });

      return local;
    },

    formatarAcao(acao) {
      switch (acao) {
        case "Remover":
          return "Removido consulta";
        case "Inserir":
          return "Adicionado nova consulta";
        case "Atualizar":
          return "Atualizado consultas";
        default:
          return "";
      }
    },

    formatarAlteracao(BureauId) {
      let bureauNome = "";

      this.listaBureaus.forEach(bureau => {
        if (bureau.Id === Number(BureauId)){
          bureauNome = bureau.Nome
        }
      })

      if(BureauId == 0) bureauNome = "-"

      return bureauNome
    },

    converterStringsParaObjetos(data) {
      if (data.Antes !== "") {
        data.Antes = JSON.parse(data.Antes);
      }
      if (data.Depois !== "") {
        data.Depois = JSON.parse(data.Depois);
      }
    },

    inverterOrdemDoArray(data) {
      const arrayInvertido = [];
      for (let i = data.length - 1; i >= 0; i--) {
        arrayInvertido.push(data[i]);
      }
      return arrayInvertido;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-view-changes {
  height: 36px;
  padding: 8px 48px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  border: none;
  color: #1f2937;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);

  &:hover {
    background: rgba(255, 255, 255, 0.6) !important;
  }
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: calc((100% - 72px) / 3);
        padding: 0 0 0 15px;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        width: 36px;

        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 8px;
      }

      &-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        white-space: nowrap;
      }

      &-info {
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: normal;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}
</style>

<style lang="scss" scoped>
  .icon-font::before{
    position: relative;
    top: 2px;
  }
</style>
